import React from 'react';

import Notifications from "./src/components/Notifications";

import {StoreProvider} from "./src/utils/store";
import reducers from "./src/utils/reducers";
import initialState from "./src/utils/store/initial-state";

export const wrapRootElement = ({element}) => {
    if (typeof window === 'undefined') return null;
    return (
        <StoreProvider initialState={initialState} reducer={reducers}>
            <Notifications/>
            {element}
        </StoreProvider>
    );
};
