export const login = (state, {payload}) => ({
    ...state,
    auth: {
        loggedIn: true,
    },
});

export const logout = (state, {payload}) => ({
    ...state,
    auth: {
        loggedIn: false,
    },
});
