import * as auth from './auth';
import * as notifications from './notifications';

import * as types from '../actions/types';

import initialState from '../store/initial-state';

export const resetStore = () => ({
    ...initialState,
});


const createReducer = handlers => (state, action) => {
    if (!handlers.hasOwnProperty(action.type)) {
        return state;
    }

    return handlers[action.type](state, action);
};

export default createReducer({
    [types.RESET_STORE]: resetStore,
    [types.LOGIN]: auth.login,
    [types.LOGOUT]: auth.logout,
    [types.ADD_NOTIFICATION]: notifications.add,
    [types.REMOVE_NOTIFICATION]: notifications.remove,
});
