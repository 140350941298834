import React, {createContext, useContext, useReducer, useEffect} from "react";

import sideEffects from "../side-effects";

export const StateContext = createContext();

const localState = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('state'));

export const StoreProvider = ({reducer, initialState, children}) => {
    try {
        const [state, dispatch] = useReducer(reducer, localState || initialState);
        const dispatchMiddleware = (action) => {
            sideEffects(action, dispatch);
            dispatch(action);
        };

        useEffect(() => {
            localStorage.setItem('state', JSON.stringify(state));
        }, [state]);

        return(
            <StateContext.Provider
                value={[state, dispatchMiddleware]}
                children={children}
            />
        );
    } catch (e) {
        // eslint-disable-next-line
        alert('Development state out of sync, please click "Reset State" in the footer');
    }
};

export const useStore = () => useContext(StateContext);
