import {v4 as uuid} from 'uuid';

function createNotification({notification, timeout}) {
    return {
        ...notification,
        id: uuid(),
        timeout,
    };
}

export const add = (state, {payload}) => ({
    ...state,
    notifications: [...state.notifications, createNotification(payload)],
});

export const remove = (state, {payload}) => ({
    ...state,
    notifications: state.notifications.filter(n => n.id !== payload.id),
});
