import {addNotification, resetStore} from "../actions";
import * as types from "../actions/types";

export default function (action, dispatch) {
    switch(action.type) {
        case types.LOGIN:
            dispatch(addNotification({message: "Successfully logged in"}));
            break;
        case types.LOGOUT:
            dispatch(resetStore());
            dispatch(addNotification({message: "Successfully logged out"}));
            window.location = "/";
            break;
        default:
            break;
    }
}
