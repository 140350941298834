import React, {useEffect} from "react";
import {useTransition, animated as a} from "react-spring";

import {useStore} from "../utils/store";
import {removeNotification} from "../utils/actions";

const Notification = ({item, props, dispatch}) => {
    useEffect(() => {
        setTimeout(() => dispatch(removeNotification(item.id)), item.timeout);
    });
    return (
        <a.div style={props}>{item.message}</a.div>
    );
};

const Notifications = () => {
    const [{notifications}, dispatch] = useStore();
    const transitions = useTransition(notifications, notification => notification.id, {
        from: {transform: 'translate3d(0,-48px,0)'},
        enter: {transform: 'translate3d(0,0px,0)'},
        leave: {transform: 'translate3d(0,-48px,0)'},
    });
    return (
        <div className="notifications">
            {transitions.map(transition => <Notification {...transition} dispatch={dispatch}/>)}
        </div>
    );
};

export default Notifications;
